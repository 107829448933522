/* eslint-disable */

import { navigate, graphql, Link } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import axios from "axios"

import Layout from "../components/layout"
import Breadcrumb from "../components/Breadcrumb"
import { mutationResetPassword, getUser } from "../services/auth"
import { isBrowser } from "../helpers"
import PersonIcon from "@material-ui/icons/Person"

const styles = theme => ({
  accountLink: {
    display: "inline-block",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontSize: 16,
    fontFamily: "Roboto Condensed",
    lineHeight: "20px",
    marginBottom: 5,
    textDecoration: "none",
    padding: "5px 20px",
    "& svg": {
      width: 18,
      height: 18,
      verticalAlign: "top",
      marginRight: 5,
    },
  },
})

const useStyles = makeStyles(theme => styles(theme))

export default function Login({ data }) {
  const classes = useStyles()

  if (isBrowser) navigate("/login")
  return null

  // const currentDetails = getUser();
  // const [email, setEmail] = useState(currentDetails.login);
  // const [error, setError] = useState(false);
  // const [success, setSuccess] = useState(false);
  // const [processing, setProcessing] = useState(false);

  // async function handleSubmit(e) {
  //   if (e) e.preventDefault();

  //   if (!isBrowser) return false;

  //   setProcessing(true);

  //   axios.post(`${process.env.GATSBY_ADMIN_URL}graphql`, mutationResetPassword({ email }), {
  //     headers: {
  //     'Content-Type': 'application/json',
  //     }
  //   }).then(res => {
  //     if (res.data.errors) {
  //       const { message } = res.data.errors[0];
  //       if (message === 'incorrect_password' || message === 'invalid_username' || 'Internal server error') {
  //         setError('Incorrect login details');
  //         setProcessing(false);
  //       }

  //       return null;
  //     }

  //     setError('');
  //     setSuccess(true);
  //     setProcessing(false);
  //   })
  // }

  //   return (
  //     <Layout
  //     meta={{}}
  //     path={'a'}
  //     cta={'a'}
  //     themeOptions={data.wp.themeOptions}
  //   >
  //     <Breadcrumb
  //         type="page"
  //         current={{
  //           title: 'Login',
  //           slug: 'login',
  //           uri: '/login'
  //         }}
  //     />
  //     {processing && <div>Processing...</div>}
  //     {success && <div>
  //       <h2>Thank you</h2>
  //       <p>Your new password has been sent to your e-mail.</p>
  //     <Link to="/account/" className={classes.accountLink}><PersonIcon /> My Account</Link>
  //     </div>}
  //     {!!error && <div>{error}</div>}
  //     {!success && <form onSubmit={handleSubmit} style={{
  //       backgroundColor: '#1b365d',
  //       margin: '50px auto',
  //       maxWidth: 768,
  //       padding: 32,
  //     }}>
  //       <h3 style={{
  //         color: 'white',
  //         fontWeight: 'normal',
  //         letterSpacing: '0.016em',
  //         fontSize: 32,
  //         marginTop: 0,
  //         marginBottom: 12,
  //       }}>Forgot your password?</h3>
  //       <p style={{
  //         color: 'white',
  //       }}>Simply enter your email address below and we will email you with instructions on how to reset it.</p>
  //       <label for="email" style={{
  //         color: 'white',
  //         fontSize: 12,
  //         lineHeight: 1.6,
  //         letterSpacing: '0.0152em',
  //         textTransform: 'uppercase',
  //         margin: 0,
  //         fontFamily: 'Overpass',
  //       }}>Email Address</label>
  //       <input name="email" name="email" type="email" placeholder="Email address" required value={email} onChange={e => setEmail(e.target.value)} style={{
  //         fontFamily: 'Overpass',
  //         backgroundColor: 'transparent',
  //         borderRadius: 20,
  //         fontSize: 12,
  //         lineHeight: 2,
  //         padding: '7px 20px',
  //         border: '1px solid white',
  //         marginBottom: 24,
  //         color: 'white',
  //         outline: 'none',
  //         display: 'block',
  //         width: '100%',
  //       }}/>
  //       <input type="submit" value="Submit" style={{
  //         width: 'auto',
  //         backgroundColor: '#c8102e',
  //         color: 'white',
  //         fontFamily: 'Bebas Neue',
  //         fontSize: 16,
  //         display: 'block',
  //         padding: '14px 40px',
  //         letterSpacing: '0.016em',
  //         textAlign: 'center',
  //         backgroundPosition: 'center right 24px',
  //         backgroundRepeat: 'no-repeat',
  //         outline: 'none',
  //         boxShadow: '0 8px 10px rgb(0 0 0 0.8)',
  //         cursor: 'pointer',
  //         borderRadius: 32,
  //         border: 'none',
  //       }} />
  //   </form>}
  // </Layout>
  //   )
}

export const query = graphql`
  query {
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`
